import './about-us.scss';
import tokenguy from '../../../../assets/images/crew/tokenguy.png';
import bhris from '../../../../assets/images/crew/bhris.png';
import clybe from '../../../../assets/images/crew/clybe.png';
import fhl from '../../../../assets/images/crew/fhl.png';
import jelly from '../../../../assets/images/crew/jelly.png';
import kingpesky from '../../../../assets/images/crew/kingpesky.png';
import passiveIncomeGuy from '../../../../assets/images/crew/passive-income-guy.png';
import sketchhubner from '../../../../assets/images/crew/sketchhubner.png';
import ktg from '../../../../assets/images/crew/ktg.png';

import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg';
import GoldFlare from '../../../../assets/images/glows/gold-3.png';

const AboutUs = () => {
  return (
    <section id="crew">
      <img className="gold-flare" src={GoldFlare} />
      <div className="content-container" data-aos="fade-up">
        <div className="section-header">
          <h4>about us</h4>
          <h2>The Crew</h2>
          <h5>A finest group of scallywags to sail the seas of Solana.</h5>
        </div>
        <div className="about-us-content">
          <div className="crew-member-container">
            <img src={tokenguy} />
            <div className="member-name">TokenGuy</div>
            <div className="member-title">Captain</div>
            <div className="member-description">
              Defi Scientist spreading wealth across the open seas of web3. Web3 educator and
              relationship builder. Married, Proud Father, Christ follower.
            </div>
            <a href="https://twitter.com/tokenomicsguy" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
          <div className="crew-member-container">
            <img src={bhris} />
            <div className="member-name">bhris</div>
            <div className="member-title">right hand</div>
            <div className="member-description">
              Web2 Social Media Manager, Marketing, Influencer; Web3 Discord Manager, Community
              Manager
            </div>
            <a href="https://twitter.com/BhrisSOL" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
          <div className="crew-member-container">
            <img src={sketchhubner} />
            <div className="member-name">SketchHubner</div>
            <div className="member-title">maties nft artist</div>
            <div className="member-description">
              Working full time with art since 2018. In his over 75 NFT projects, sketch has put his
              heart, mind and style into every artwork he makes.
            </div>
            <a href="https://twitter.com/SketchHubner" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
          <div className="crew-member-container">
            <img src={clybe} />
            <div className="member-name">Clyde</div>
            <div className="member-title">Partnership/Collab Expert</div>
            <div className="member-description">
              Web3 Entrepreneur specialized in Growth, Marketing and Product Advising. Navigating
              the Solana ecosystem and making connections.
            </div>
            <a href="https://twitter.com/solclyde_" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
          <div className="crew-member-container">
            <img src={passiveIncomeGuy} />
            <div className="member-name">Passive Income Guy</div>
            <div className="member-title">project investigator</div>
            <div className="member-description">
              Analyst\NFT trader. Started trading for fun in 2018. Made some money and lost some
              money but learned a lot on the way. Mainly focus on Projects that airdrop SOL.
            </div>
            <a href="https://twitter.com/Passive_IncomeG" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
          <div className="crew-member-container">
            <img src={fhl} />
            <div className="member-name">fhlcreative</div>
            <div className="member-title">designer</div>
            <div className="member-description">
              Follower of Christ. Husband. Father. Creative Director of Product Design at Unfold Co.
              Web3 nerd.
            </div>
            <a href="https://twitter.com/fhlcreative" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
          <div className="crew-member-container">
            <img src={kingpesky} />
            <div className="member-name">KingPesky™</div>
            <div className="member-title">Designer</div>
            <div className="member-description">
              Co-Founder of DroniesNFT & Unfold Co. // Clients: Solana, Portals, GenGo, Safepal,
              Ripple, FB
            </div>
            <a href="https://twitter.com/peskyhands" target="_blank" rel="noreferrer">
              <Twitter className="twitter" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
