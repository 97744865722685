import { useState } from 'react';
import './navmenu.scss';

import { ReactComponent as CaretDown } from '../../assets/icons/caretDown.svg';
import { ReactComponent as Doubloons } from '../../assets/icons/skull_icon.svg';
import { ReactComponent as Maties } from '../../assets/icons/pfp_icon.svg';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import clsx from 'clsx';

const Navmenu = () => {
  const [isStakingMenuOpen, setIsStakingMenuOpen] = useState(false);

  return (
    <nav>
      <ol>
        <li>
          <AnchorLink href="#collections">collections</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#treasure-map">map</AnchorLink>
        </li>
        <li>
          <AnchorLink href="#crew">crew</AnchorLink>
        </li>
        <li>
          <a href="https://dashboard.liquidcapital.gg" target="_blank" rel="noreferrer">
            dashboard
          </a>
        </li>
        <li className={clsx(isStakingMenuOpen && 'active')}>
          <div className="li-content" onClick={() => setIsStakingMenuOpen(!isStakingMenuOpen)}>
            <a>staking</a>
            <CaretDown />
          </div>
          {isStakingMenuOpen && (
            <div className="staking-menu">
              <a href="https://staking.liquidcapital.gg" target="_blank" rel="noreferrer">
                <Doubloons /> Doubloons
              </a>
              <a href="https://maties.liquidcapital.gg" target="_blank" rel="noreferrer">
                <Maties /> Maties
              </a>
            </div>
          )}
        </li>
      </ol>
    </nav>
  );
};

export default Navmenu;
