import './logo.scss';

import { ReactComponent as LC } from '../../assets/logos/LC_logo.svg';
import { ReactComponent as LogoMobile } from '../../assets/logos/LC_logo_mobile.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import clsx from 'clsx';

interface LogoProps {
  isCollapsed: boolean;
}

const Logo = ({ isCollapsed }: LogoProps) => {
  return (
    <AnchorLink href="#home">
      <div className={clsx('logo-container', isCollapsed && 'small')}>
        <LC />
        <LogoMobile className="mobile-logo" />
        <div className="liquid-capital">Liquid capital</div>
      </div>
    </AnchorLink>
  );
};

export default Logo;
