import './collections.scss';
import MatiesThumbnail from '../../../../assets/images/maties_thumbnail.png';
import DoubloonsThumbnail from '../../../../assets/images/doubloons_thumbnail.png';
import GoldFlare from '../../../../assets/images/glows/gold-1.png';
import BlueFlare from '../../../../assets/images/glows/blue-2.png';
import { ReactComponent as PFP } from '../../../../assets/icons/pfp_icon.svg';
import { ReactComponent as Skull } from '../../../../assets/icons/skull_icon.svg';

import AnchorLink from 'react-anchor-link-smooth-scroll';

const Collections = () => {
  return (
    <section id="collections">
      <img className="gold-flare" src={GoldFlare} />
      <img className="blue-flare" src={BlueFlare} />
      <div className="content-container" data-aos="fade-up">
        <div className="section-header">
          <h4>Our signature Collections</h4>
          <h2>Maties & Doubloons</h2>
          <h5>
            Two NFT collections will power Liquid Capital, bringing both fun and value to holders.
          </h5>
        </div>
        <div className="collections-content">
          <div className="collections-item">
            <div className="collection-thumbnail">
              <img src={MatiesThumbnail} />
            </div>
            <div className="collection-title">Maties</div>
            <div className="collection-description">PFP Collection of 2.5k NFTs</div>
            <AnchorLink href="#maties">
              <button className="button button-secondary">
                <PFP />
                Jump to maties
              </button>
            </AnchorLink>
          </div>
          <div className="divider-vertical" />
          <div className="divider-horizontal" />
          <div className="collections-item">
            <div className="collection-thumbnail">
              <img src={DoubloonsThumbnail} className="coins" />
            </div>
            <div className="collection-title">Doubloons</div>
            <div className="collection-description">Value Share Collection of 5k NFTs</div>
            <AnchorLink href="#doubloons">
              <button className="button button-secondary">
                <Skull />
                Jump to Doubloons
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collections;
