import './Home.scss';

// import Solana from '../../../assets/logos/solana.png';
// import Hadeswap from '../../../assets/logos/hadeswap.png';
// import Sharkyfi from '../../../assets/logos/sharkyfi.png';
// import Frakt from '../../../assets/logos/frakt.png';
import LandingBoat from '../../../assets/images/Ship-landing.png';
import LandingBoatMobile from '../../../assets/images/ship-mobile.png';
import BlueGlow from '../../../assets/images/glows/blue-1.png';
import CaptainWeb from '../../../assets/images/captain-web.png';
import CaptainMobile from '../../../assets/images/captain-mobile.png';
import Quotes from '../../../assets/images/quotes.png';
import PalmWeb from '../../../assets/images/palm-web.png';
import PalmMobile from '../../../assets/images/palm-mobile.png';

import { ReactComponent as Discord } from '../../../assets/icons/discord.svg';
import { ReactComponent as Solana } from '../../../assets/logos/solana.svg';
import { ReactComponent as Hadeswap } from '../../../assets/logos/hadeswap.svg';
import { ReactComponent as Sharky } from '../../../assets/logos/sharky.svg';
import { ReactComponent as Frakt } from '../../../assets/logos/frakt.svg';
import Collections from '../components/collections/collections';
import Maties from '../components/maties/maties';
import Doubloons from '../components/doubloons/doubloons';
import TreasureMap from '../components/treasure-map/treasure-map';
import AboutUs from '../components/about-us/about-us';
import Community from '../components/community/community';

const Home = () => {
  return (
    <main id="home">
      <section className="landing-screen">
        <img src={LandingBoat} className="boat" alt="Boat" />
        <img src={BlueGlow} className="blue-glow" alt="Glow" />
        <div className="content-container">
          <div className="landing-content-container">
            <>
              <div className="col" data-aos="zoom-out">
                <h1>Bringing Value to our Holders</h1>
                <p>
                  Sharing Value back to our holders, sourced from our portfolio, all while having
                  fun.
                </p>
                <a href="https://discord.gg/liquidcapital" target="_blank" rel="noreferrer">
                  <button className="button button-primary">
                    <Discord />
                    Join discord
                  </button>
                </a>
              </div>
            </>
          </div>
        </div>
        <img src={LandingBoatMobile} className="boat-mobile" alt="Boat" />
      </section>
      <section className="protocols" data-aos="fade-up">
        <div className="content-container">
          <div className="protocols-header">
            We use DeFi protocols to generate revenue for our Learn2Earn platform.
          </div>
          <div className="protocols-list">
            <div className="protocols-list-item">
              <Frakt />
            </div>
            <div className="protocols-list-item">
              <Solana />
            </div>
            <div className="protocols-list-item">
              <Hadeswap />
            </div>
            <div className="protocols-list-item">
              <Sharky />
            </div>
          </div>
        </div>
      </section>
      <section className="welcome-aboard" data-aos="fade-up">
        <div className="content-container">
          <div className="card">
            <img src={CaptainWeb} className="captain-web" />
            <img src={Quotes} className="quotes" />
            <img src={PalmWeb} className="palm-web" />
            <div className="welcome-aboard-content-container">
              <div className="welcome-aboard-title">ARRGH! welcome aboard</div>
              <div className="welcome-aboard-text">
                We are excited to have you join our crew as we sail the seven seas in search of
                treasure. As members of the Liquid Capital, you will be part of a tight-knit group
                of pirates who work together and face any challenge along the way. #YOHO
              </div>
              <div className="welcome-aboard-footer">~ The Captain</div>
            </div>
            <div className="mobile-images-container">
              <img src={CaptainMobile} className="captain-mobile" />
              <img src={PalmMobile} className="palm-mobile" />
            </div>
          </div>
        </div>
      </section>
      <Collections />
      <Maties />
      <Doubloons />
      <TreasureMap />
      <AboutUs />
      <Community />
    </main>
  );
};

export default Home;
