import './footer.scss';

const Footer = () => {
  return (
    <section id="footer">
      <div className="content-container">
        <ul>
          <li className="footer-info">©2023 All Rights Reserved. Liquid Capital</li>
          {/* <li className="footer-menu-item">PRIVACY POLICY</li>
          <li className="footer-menu-item">TERMS OF SERVICE</li> */}
        </ul>
      </div>
    </section>
  );
};

export default Footer;
