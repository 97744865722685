import './community.scss';
import { ReactComponent as Logo } from '../../../../assets/logos/LC_logo.svg';
import { ReactComponent as Discord } from '../../../../assets/icons/discord.svg';
import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg';

const Community = () => {
  return (
    <section id="community">
      <div className="content-container" data-aos="fade-up">
        <div className="community-content">
          <Logo className="lc-logo" />
          <div className="community-header">Community</div>
          <div className="community-text">
            Our community is about having fun, growing together, and building value through our
            culture and unique events & giveaways.
          </div>
          <div className="socials">
            <a href="https://discord.gg/liquidcapital" target="_blank" rel="noreferrer">
              <button className="button button-primary">
                <Discord />
                Join discord
              </button>
            </a>
            <a
              href="https://twitter.com/LiquidCapNFT"
              target="_blank"
              className="twitter"
              rel="noreferrer"
            >
              <button className="button button-secondary">
                <Twitter />
                follow us
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
