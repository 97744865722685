import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ThemeProvider from './ThemeProvider';

const ErrorFallback = () => {
  return (
    <div className="error-screen" role="alert">
      It seems something went wrong. <br /> Please try again.
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserRouter>
        <ThemeProvider>
          <ToastContainer />
          {children}
        </ThemeProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
