import './header.scss';

import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import Navmenu from 'components/navmenu/navmenu';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Logo from 'components/logo/logo';

const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 80) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    });
  });

  return (
    <header className={clsx('app-header', isCollapsed && 'collapsed')}>
      <div className="header-content">
        <Logo isCollapsed={isCollapsed} />
        <Navmenu />
        <div className="socials">
          <a href="https://discord.gg/liquidcapital" target="_blank" rel="noreferrer">
            <Discord />
          </a>
          <a
            href="https://twitter.com/LiquidCapNFT"
            target="_blank"
            rel="noreferrer"
            className="twitter"
          >
            <Twitter />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
