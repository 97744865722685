import './maties.scss';
import Ubik from '../../../../assets/logos/ubik.png';
import PirateGang from '../../../../assets/images/pirate-gang.png';
import PirateGangMobile from '../../../../assets/images/pirates_mobile.png';
import WoodBig from '../../../../assets/images/wood_big.png';
import WoodMobile from '../../../../assets/images/wood_mobile.png';
import Parrot from '../../../../assets/images/parrot.png';
import WlIcon from '../../../../assets/icons/wl_icon.png';
import MatiesIcon from '../../../../assets/icons/maties_icon.png';
import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg';
import { ReactComponent as Skull } from '../../../../assets/icons/skull_icon.svg';

const Maties = () => {
  return (
    <section id="maties">
      <div className="content-container" data-aos="fade-up">
        <div className="section-header">
          <h4>PFP Collection</h4>
          <h2>Maties</h2>
          <h5>
            The faces of Liquid Capital, a collection of high quality, unique, ruthless, and savy
            pirates
          </h5>
        </div>
        <div className="maties-info-content">
          <div className="maties-item">
            <div className="maties-title">Collection Size</div>
            <div className="maties-value">2,500</div>
          </div>
          <div className="divider-vertical" />
          <div className="divider-horizontal" />
          <div className="maties-item">
            <div className="maties-title">Mint Price</div>
            <div className="maties-value">
              0.49<span>sol</span>
            </div>
          </div>
          <div className="divider-vertical" />
          <div className="divider-horizontal" />
          <div className="maties-item">
            <div className="maties-title">Wen</div>
            <div className="maties-value">Sold Out</div>
          </div>
          <div className="divider-vertical" />
          <div className="divider-horizontal" />
          <div className="maties-item">
            <div className="maties-title">Where</div>
            <div className="maties-value">
              <img src={Ubik} alt="ubik" />
            </div>
          </div>
        </div>
        <div className="maties-mint-info">
          <div className="wood-container">
            <img src={WoodBig} className="wood" />
            <img src={WoodMobile} className="wood-mobile" />
            <img src={Parrot} className="parrot" />
            <div className="wood-content-container">
              <div className="wood-icons">
                <img src={MatiesIcon} />
                <span>=</span>
                <img src={WlIcon} />
              </div>
              <div className="wood-content-text">
                Every Maties NFT earns $HADES token while staked
              </div>
            </div>
          </div>
        </div>
        <div className="pirates-container">
          <div className="pirates-info">
            <div className="tbd-value">
              *Public mint, Raffled using Ubiks clover platform at 0.49 sol with a raffle ticket fee
              of .089 each.
            </div>
            <a
              href="https://twitter.com/LiquidCapNFT"
              target="_blank"
              className="twitter"
              rel="noreferrer"
            >
              <button className="button button-secondary">
                <Twitter />
                follow us
              </button>
            </a>
          </div>
          <img src={PirateGang} className="pirates-gang" />
          <img src={PirateGangMobile} className="pirates-gang-mobile" />
        </div>
      </div>
    </section>
  );
};

export default Maties;
