import './doubloons.scss';
import MintBudget from '../../../../assets/images/mint_budget.png';
import ValueShare from '../../../../assets/images/value_share.png';
import GoldCoin from '../../../../assets/images/gold_coin.png';
import SilverCoin from '../../../../assets/images/silver_coin.png';
import DoubloonsCoins from '../../../../assets/images/doubloons_coins_img.png';

import GoldFlare from '../../../../assets/images/glows/gold-2.png';
import BlueFlare from '../../../../assets/images/glows/blue-3.png';

import { ReactComponent as Twitter } from '../../../../assets/icons/twitter.svg';
import { ReactComponent as Skull } from '../../../../assets/icons/skull_icon.svg';

const Doubloons = () => {
  return (
    <section id="doubloons">
      <img className="gold-flare" src={GoldFlare} />
      <img className="blue-flare" src={BlueFlare} />
      <div className="content-container" data-aos="fade-up">
        <div className="section-header">
          <h4>Value share Collection</h4>
          <h2>Doubloons</h2>
          <h5>
            A collection of high quality, 3D animated doubloons, providing value share to holders.
          </h5>
        </div>
        <div className="doubloons-content">
          <div className="card">
            <div className="card-item">
              <div className="card-item-title">Collection Size</div>
              <div className="value-big">5,000</div>
            </div>
            <div className="divider-horizontal" />
            <div className="card-item">
              <div className="card-item-title">NFT shares</div>
              <div className="col">
                <div className="card-value-item">
                  <img src={SilverCoin} />
                  <div className="col">
                    <div className="shares-title">Silver Doubloon</div>
                    <div className="shares-value">1 Share / 4 SOL</div>
                  </div>
                </div>
                <div className="card-value-item">
                  <img src={GoldCoin} />
                  <div className="col">
                    <div className="shares-title">Gold Doubloon</div>
                    <div className="shares-value">2 Shares / 8 SOL</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider-horizontal" />
            <div className="card-item">
              <div className="card-item-title">Wen</div>
              <div className="value-big">Sold out</div>
            </div>
          </div>
          <img src={DoubloonsCoins} className="doubloons-coins" />
        </div>

        <div className="doubloons-infographics-container">
          <div className="info-container">
            <div className="info-title">Mint budget</div>
            <img src={MintBudget} className="mint-budget" />
          </div>
          <div className="info-container value-share-container">
            <div className="info-title">Value share</div>
            <img src={ValueShare} className="value-share" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Doubloons;
