import { Navigate, Route, Routes } from 'react-router-dom';

import HomeRoutes from 'features/home/routes';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<HomeRoutes />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
