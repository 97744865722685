import './treasure-map.scss';
import TreasureMapImg from '../../../../assets/images/treasure_map.png';
import TreasureMapMobile from '../../../../assets/images/treasure-map-mobile.png';

const TreasureMap = () => {
  return (
    <section id="treasure-map">
      <div className="content-container" data-aos="fade-up">
        <img className="treasure-map-web" src={TreasureMapImg} />
        <img className="treasure-map-mobile" src={TreasureMapMobile} />
      </div>
    </section>
  );
};

export default TreasureMap;
