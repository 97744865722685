import AOS from 'aos';
import './styles/index.scss';
import 'aos/dist/aos.css';
import Header from 'components/header/header';
import AppRoutes from 'routes';
import Footer from 'components/footer/footer';

function App() {
  AOS.init();

  return (
    <div className="app">
      <Header />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
