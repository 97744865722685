import { Navigate, Route, Routes } from 'react-router-dom';

import Home from './Home';

const HomeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default HomeRoutes;
